
import { Options, Vue } from 'vue-class-component';
import axios from 'axios';
import Spinner from '@/loaders/spinner.vue';

@Options({
  props: {
    msg: String,
    msgSpinner: { type: String },
  },
  components: { Spinner },
  computed: {},
  methods: {
    isLoading() {
      this.isLoading = true;
    },
  },
  data() {
    return {
      isLoading: true,
      setProjects: [],
      orgName: this.$route.params.id,
    };
  },
  mounted() {
    const api = `https://api.casjay.coffee/api/v1/git/repos/${this.$route.params.id}`;
    axios.get(api).then((response) => {
      this.setProjects = response.data;
      if (!this.setProjects) {
        this.isLoading = true;
      }
    });
    this.isLoading = false;
  },
})
export default class myProjects extends Vue {}
