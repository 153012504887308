
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'spinner',
  props: {
    msg: { type: String },
    msgSpinner: { type: String },
    loading: { type: Boolean },
    color: {
      type: String,
      default: '#000',
    },
    height: {
      type: Number,
      default: 64,
    },
    width: {
      type: Number,
      default: 64,
    },
  },
});
