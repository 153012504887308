
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    msg: { type: String },
    msgSpinner: { type: String },
  },
  components: {},
})
export default class About extends Vue {}
