
import { Options, Vue } from 'vue-class-component';
import axios from 'axios';
import Spinner from '@/loaders/spinner.vue';

@Options({
  props: {
    msg: String,
    msgSpinner: { type: String },
  },
  components: { Spinner },
  computed: {},
  methods: {
    isLoading() {
      this.isLoading = true;
    },
  },
  data() {
    return {
      isLoading: true,
      setContact: [],
    };
  },
  mounted() {
    axios
      .get('https://raw.githubusercontent.com/casjay/casjay/main/profile.json')
      .then((response) => {
        this.setContact = response.data;
        if (!this.setContact) {
          this.isLoading = true;
        }
      });
    this.isLoading = false;
  },
})
export default class myContact extends Vue {}
